import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store';

const {state:S} = store;

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
const Settings = () => import('@/views/Settings')

// goods
const Processing = () => import('@/views/goods/Processing')
const Goods = () => import('@/views/goods/Goods')
const ConfirmedES = () => import('@/views/goods/ConfirmedES')
const GoodsInfo = () => import('@/views/goods/GoodsInfo')
const ImageManage = () => import('@/views/goods/ImageManage')
const Terminated = () => import('@/views/goods/Terminated')
const Mapped = () => import('@/views/goods/Mapped')
const MappedImageCheck = () => import('@/views/goods/MappedImageCheck')
const CfImageCheck = () => import('@/views/goods/CFImageCheck')
const MPImageCheck = () => import('@/views/goods/MPImageCheck')
const DiscountRate = () => import('@/views/goods/DiscountRate')
const GoodsIntegrity = () => import('@/views/goods/Integrity/GoodsIntegrity')
// const ForbiddenWord = () => import('@/views/goods/Integrity/ForbiddenWord') // 상품 건전성 관리로 통합
const LambdaImageCheck = () => import('@/views/goods/LambdaImageCheck')
const ES = () => import('@/views/goods/ES/ES')
// const EpImage = () => import('@/views/goods/EP/EpImage')
const NaverEP = () => import('@/views/goods/EP/NaverEP')
const Shopify = () => import('@/views/goods/Shopify')
const Shein = () => import('@/views/goods/Shein')

// master
const MasterList = () => import('@/views/master/MasterList')
const MasterMatching = () => import('@/views/master/MasterMatching')
const MasterGroupList = () => import('@/views/master/MasterGroupList')
const MasterOrderStat = () => import('@/views/master/MasterOrderStat')
const MasterMatchingRequest = () => import('@/views/master/MasterRequestList')
const SkuPattern = () => import('@/views/master/SkuPattern')

// shop
const Shop = () => import('@/views/shop/Shop')
const PartnerGrade = () => import('@/views/shop/partnerGrade/Main')
const ImageTrimSample = () => import('@/views/shop/ImageTrimSample')

// price
const PriceLimit = () => import('@/views/price/PriceLimit/PriceLimit.vue')
const Discount = () => import('@/views/price/Discount')
const RenewPrice = () => import('@/views/price/RenewPrice')
const PriceRebate = () => import('@/views/price/PriceRebate')
const Margin = () => import('@/views/price/Margin')
// const Promotion = () => import('@/views/price/Promotion')
// const GoodsMargin = () => import('@/views/price/GoodsMargin')

// order
const Order = () => import('@/views/order/Order')
const Reward = () => import('@/views/order/Reward/Reward')
const DeliveryStat = () => import('@/views/order/stat/Stat')
const DeliveryBoard = () => import('@/views/order/DeliveryBoard')
const OrderES = () => import('@/views/order/OrderES')
const ApiOrder = () => import('@/views/order/ApiOrder')
const ApiOrderResult = () => import('@/views/order/ApiOrderResult')
const OrderStat = () => import('@/views/order/OrderStat')
const GodoOrderStat = () => import('@/views/order/GodoOrderStat')
const RpaOrder = () => import('@/views/order/RpaOrder');
const rewardSetting = () => import('@/views/order/RewardSetting');

// data
const KRdashboard = () => import('@/views/data/KRdashboard')
const Indicator = () => import('@/views/data/Indicator')
const GoodsStat = () => import('@/views/data/GoodsStat')
// const NaverPriceHeatMap = () => import('@/views/data/NaverPriceHeatMap')
const DataStore = () => import('@/views/data/Store')
const StoreLibs = () => import('@/views/data/StoreLibs')
const DataMenu = () => import('@/views/data/Menu')
const DataDashboard = () => import('@/views/data/Dashboard')

// ad
const AdList = () => import('@/views/ad/AdList')
const NaverADApi = () => import('@/views/ad/NaverAD')

// meta
const MasterMeta = () => import('@/views/meta/MasterMeta')
const Brand = () => import('@/views/meta/Brand')
const Category = () => import('@/views/meta/Category')
const Color = () => import('@/views/meta/Color')
const OriginMapping = () => import('@/views/meta/OriginMapping')
const SearchKeyword = () => import('@/views/meta/SearchKeyword')
const MetaOptionMng = () => import('@/views/meta/MetaOptionMng.vue')

// 외부몰
// const SSProductRegist = () => import('@/views/ss/ProductRegist')
// const SSProductList = () => import('@/views/ss/ProductList')
// const SSOrderList = () => import('@/views/ss/OrderList')
// const SSDiscount = () => import('@/views/ss/Discount')
// const SSDiscountSet = () => import('@/views/ss/DiscountSet')
// const SSErrorLog = () => import('@/views/ss/ErrorLog')

// customer
const CustomerPrivacy = () => import('@/views/customer/Privacy')

// user
const Users = () => import('@/views/user/Users')
const CSUsers = () => import('@/views/user/CSUsers')
const Mypage = () => import('@/views/user/Mypage')
const AuditLogs = () => import('@/views/user/AuditLogs')

// dev
// const Glog = () => import('@/views/dev/Glog')
// const HubGodoSync = () => import('@/views/dev/HubGodoSync')
// const HubGodoShopDiff = () => import('@/views/dev/HubGodoShopDiff')
const Queue = () => import('@/views/dev/Queue')
const QueueDetail = () => import('@/views/dev/QueueDetail')
const TableWiki = () => import('@/views/dev/TableWiki')
const ShopStat = () => import('@/views/dev/ShopStat')

// Godo
// const Review = () => import('@/views/godo/Review') // 발란몰 어드민 이전 2023/05/03
// const QnaGoods = () => import('@/views/godo/QnaGoods') // 발란몰 어드민 이전 2023/05/10
// const QnaType = () => import('@/views/godo/QnaType') // 발란몰 어드민 이전 2023/05/10
// const Qna = () => import('@/views/godo/Qna') // 발란몰 어드민 이전 2023/05/10
// const MainHome = () => import('@/views/godo/MainHome') // 발란몰 어드민 이전 2023/04/06
// const MainHomeSet = () => import('@/views/godo/MainHomeSet') // 발란몰 어드민 이전 2023/04/06
// const CategoryHome = () => import('@/views/godo/CategoryHome') // 발란몰 어드민 이전 2023/04/14
// const CategoryHomeSet = () => import('@/views/godo/CategoryHomeSet') // 발란몰 어드민 이전 2023/04/14
const Timesale = () => import('@/views/godo/Timesale')
const TimesaleSet = () => import('@/views/godo/TimesaleSet')
const PopupDetail = () => import('@/views/godo/PopupDetail')
// const SaleDropBannerSets = () => import('@/views/godo/SaleDropBannerSets') // 발란몰 어드민 이전 2023/07/11
// const SaleDropBanner = () => import('@/views/godo/SaleDropBanner') // 발란몰 어드민 이전 2023/07/11
// const Popup = () => import('@/views/godo/Popup') // 발란몰 어드민 이전 2023/04/28
// const SaleDropGoodsSets = () => import('@/views/godo/SaleDropGoodsSets') // 발란몰 어드민 이전 2023/04/28
// const SaleDropGoods = () => import('@/views/godo/SaleDropGoods') // 발란몰 어드민 이전 2023/04/28
// const BannerPageList = () => import('@/views/godo/BannerPageList')
// const BannerPageDetail = () => import('@/views/godo/BannerPageDetail') // 발란몰 어드민 이전 2023/04/28
// const EventListOld = () => import('@/views/godo/event/EventList') // 발란몰 어드민 이전 2023/07/11
// const EventDetail = () => import('@/views/godo/event/EventDetail') // 발란몰 어드민 이전 2023/07/11
// const EventList = () => import('@/views/event/EventList') // 발란몰 어드민 이전 2023/07/11
// const Event = () => import('@/views/event/Event') // 발란몰 어드민 이전 2023/07/11
const CustomerList = () => import('@/views/customer/List')


// link
const Link = () => import('@/views/link/Link')

// pages
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')
const Page403 = () => import('@/views/pages/Page403')
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')

Vue.use(Router);

const auth = (to, from, next) => {
  if (S.user.roles) { // 로그인 되어있음
    // console.log(from, to, S.user.roles);
    if (!to.meta || !to.meta.role || ~S.user.roles.indexOf(to.meta.role)
      || Vue.prototype.$utils.typeOf(to.meta.role) === 'array' && to.meta.role.some(e=>~S.user.roles.indexOf(e))) {
      next();
    } else { // 로그인 되어있으나 권한없음
      console.log(from, to, S.user.roles, to.meta.role);
      // next(false);
      next('/pages/403');
      alert('권한이 없습니다');
    }
  } else {
    next('/pages/login?go=' + encodeURIComponent(to.path));
  }
  // client hash 가 다른 경우 refresh 한다.
  if (!window.webpackHotUpdate && window.currentHash !== window.newHash && !window.reloadTried) {
    window.reloadTried = true; // 1회성 reload
    setTimeout(() => {
      location.reload();
    }, 50);
  }
};

const pages = [
  { path: 'dashboard', name: 'Dashboard', component: Dashboard, meta: {role: 'META_R'}, },
  // GOODS
  { path: 'processing/:no?', name: '상품등록 및 수정', component: Processing, meta: {role: 'GOODS_W'}, },
  { path: 'goods/:no?', name: '등록상품조회', component: Goods, meta: {role: 'CONFIRMED_R'}, },
  { path: 'confirmedES/:no?', name: '등록상품검색', component: ConfirmedES, meta: {role: 'CONFIRMED_R'}, },
  { path: 'terminated/:no?', name: '종료상품조회', component: Terminated, meta: {role: 'CONFIRMED_R'}, },
  { path: 'goodsInfo', name: '상품정보개선', component: GoodsInfo, meta: {role: 'GOODS_R'}, },
  { path: 'imageManage', name: '이미지 관리', component: ImageManage, meta: {role: 'DEV'}, },
  { path: 'mapped/image', name: 'Mapped 이미지체크', component: MappedImageCheck, meta: {role: 'GOODS_R'}, },
  { path: 'mapped/:id?', name: 'Mapped 조회', component: Mapped, meta: {role: 'GOODS_R'}, },
  { path: 'discountRate', name: '할인율 조회', component: DiscountRate, meta: {role: 'GOODS_R'}, },
  { path: 'goodsIntegrity', name: '상품 건전성 관리', component: GoodsIntegrity, meta: {role: 'FWORD_W'}, },
  // { path: 'forbiddenWord', name: '금칙어 관리', component: ForbiddenWord, meta: {role: 'FWORD_W'}, }, // 상품 건전성 관리로 통합
  { path: 'image/confirmed/:no', name: 'CF 이미지체크', component: CfImageCheck, meta: {role: 'SKU_OUTSOURCE'}, },
  { path: 'image/mapped/:gid', name: 'MP 이미지체크', component: MPImageCheck, meta: {role: 'GOODS_R'}, },
  { path: 'img/lambda', name: '이미지처리체크', component: LambdaImageCheck, meta: {role: 'GOODS_R'}, },
  { path: 'ES', name: '검색엔진 조회', component: ES, meta: {role: 'GOODS_R'}, },
  { path: 'naverEP', name: 'Naver EP 관리', component: NaverEP, meta: {role: 'SKU_OUTSOURCE'}, },
  // { path: 'epImage', name: 'EP 이미지 관리', component: EpImage, meta: {role: 'SKU_OUTSOURCE'}, },
  { path: 'shopify', name: 'Shopify 상품관리', component: Shopify, meta: {role: 'GOODS_R'}, },
  { path: 'shein', name: 'Shein 상품관리', component: Shein, meta: {role: 'GOODS_R'}, },

  // MASTER SKU
  { path: 'master/list/:id?', name: '마스터SKU 생성/조회', component: MasterList, meta: {role: 'SKU_OUTSOURCE'}, },
  { path: 'master/matching/:no?', name: '마스터SKU 매칭', component: MasterMatching, meta: {role: 'SKU_OUTSOURCE'}, },
  { path: 'master/goodsGroup/:no?', name: '가격비교 (상품별)', component: MasterGroupList, meta: {role: 'SKU_OUTSOURCE'}, },
  { path: 'master/orderStat', name: '가격비교 (파트너별)', component: MasterOrderStat, meta: {role: 'SKU_OUTSOURCE'}, },
  { path: 'master/matchingRequest', name: '카탈로그 매칭 수정요청', component: MasterMatchingRequest, meta: {role: 'SKU_OUTSOURCE'}, },
  { path: 'master/skuSettings', name: '통합 SKU 관리', component: SkuPattern, meta: {role: 'SKU_OUTSOURCE'}, },
  // SHOP
  { path: 'shop/:id?', name: 'SHOP 정보', component: Shop, meta: {role: 'PARTNER_R'}, },
  { path: 'partner/grade', name: '파트너 등급 관리', component: PartnerGrade, meta: {role: 'PARTNER_R'}, },
  { path: 'shop/imageTrimSample', name: '이미지 Trim 샘플', component: ImageTrimSample, meta: {role: 'SHOP_R'}, },
  // PRICE
  { path: 'priceLimit/:id?', name: '상품별 정액 할인', component: PriceLimit, meta: {role: 'PRICE_X'}, },
  { path: 'discount/:id?', name: '할인율 설정', component: Discount, meta: {role: 'PRICE_R'}, },
  { path: 'renewPrice', name: '가격 갱신', component: RenewPrice, meta: {role: 'PRICE_X'}, },
  { path: 'priceRebate/:shop_id?', name: '병행가격설정', component: PriceRebate, meta: {role: 'PRICE_X'}, },
  { path: 'margin', name: '마진 설정', component: Margin, meta: {role: 'PRICE_R'}, },
  // { path: 'promotion', name: '프로모션', component: Promotion, meta: {role: 'PRICE_R'}, },
  // { path: 'goodsMargin', name: '공헌이익 조회', component: GoodsMargin, meta: {role: 'PRICE_R'}, },
  // ORDER
  { path: 'order', name: '주문 관리', component: Order, meta: {role: 'DELI_R'}, },
  { path: 'reward', name: '고객보상현황', component: Reward, meta: {role: 'ORDER_R'}, },
  { path: 'deliveryStat', name: '주문/반품 통계', component: DeliveryStat, meta: {role: 'ORDER_R'}, },
  { path: 'deliveryBoard/:no?/:detailno?', name: '배송보드', component: DeliveryBoard, meta: {role: 'DELI_R'}, },
  { path: 'order/es/:no?/:detailno?', name: '배송보드 검색엔진', component: OrderES, meta: {role: 'DELI_R'}, },
  { path: 'apiOrder', name: 'API 주문', component: ApiOrder, meta: {role: 'ORDER_X'}, },
  { path: 'apiOrderResult', name: 'API 주문결과', component: ApiOrderResult, meta: {role: 'ORDER_R'}, },
  { path: 'orderStat', name: '주문통계', component: OrderStat, meta: {role: ['BALAANEER', 'ORDER_R']}, },
  { path: 'godoOrderStat', name: '기간매출통계', component: GodoOrderStat, meta: {role: 'MARKETING_EXT'}, },
  { path: 'rpaOrder', name: 'RPA 주문', component: RpaOrder, meta: {role: 'ORDER_X'}, },
  { path: 'rewardSetting', name: '고객 보상 제외 설정', component: rewardSetting, meta: {role: 'ORDER_X'}, },
  // data
  { path: 'data/kr', name: 'KR 대시보드', component: KRdashboard, meta: {role: 'BALAANEER'}, },
  { path: 'data/store/:no?', name: 'Data Store', component: DataStore, meta: {role: ['BALAANEER', 'GOODS']}, },
  { path: 'data/storeLibs/:no?', name: 'Data Store Libs', component: StoreLibs, meta: {role: ['DEV']}, },
  { path: 'data/menu/:no?', name: 'Data Menu', component: DataMenu, meta: {role: ['BALAANEER', 'MARKETING_EXT', 'E_GOODS_R'], type: 'full'}, },
  { path: 'data/indicator', name: '지표 정의', component: Indicator, meta: {role: 'BALAANEER'}, },
  { path: 'data/goodsStat', name: '상품통계', component: GoodsStat, meta: {role: ['GOODS_R', 'MARKETING_EXT']}, },
  // { path: 'data/naver', name: 'Naver 최저가 히트맵', component: NaverPriceHeatMap, meta: {role: 'BALAANEER'}, },
  { path: 'data/dashboard/:no?', name: 'Data Dashboard', component: DataDashboard, meta: {role: 'DEV'}, },

  // ad
  { path: 'ad/list', name: '광고 리스트/충전', component: AdList, meta: {role: 'AD_R'}, },
  { path: 'ad/naver', name: 'Naver AD Api', component: NaverADApi, meta: {role: 'GOODS_R'}, },
  // meta
  { path: 'meta/master', name: '마스터 메타 관리', component: MasterMeta, meta: {role: 'META_W'}, },
  { path: 'meta/brand/:id?', name: '브랜드 관리', component: Brand, meta: {role: 'META_W'}, },
  { path: 'meta/category/:id?', name: '카테고리 관리', component: Category, meta: {role: 'META_W'}, },
  { path: 'meta/color/:id?', name: '색 분류', component: Color, meta: {role: 'META_W'}, },
  { path: 'meta/originMapping/', name: '원산지 분류', component: OriginMapping, meta: {role: 'META_W'}, },
  { path: 'meta/searchKeyword', name: '검색 키워드 관리', component: SearchKeyword, meta: {role: 'META_W'}, },
  { path: 'meta/option', name: '발란 옵션 관리', component: MetaOptionMng, meta: {role: 'META_W'}, },
  // 외부몰
  // { path: 'ss/productRegist', name: '외부몰 상품등록', component: SSProductRegist, meta: {role: 'EXT_GOODS_R'} },
  // { path: 'ss/productList/:id?', name: 'SS 등록상품조회', component: SSProductList, meta: {role: 'EXT_GOODS_R'} },
  // { path: 'ss/orderList/:id?/:poid?', name: 'SS 주문조회', component: SSOrderList, meta: {role: 'EXT_GOODS_R'} },
  // { path: 'ss/discount/:id?', name: 'SS 할인관리', component: SSDiscount, meta: {role: 'BALAANEER'}, },
  // { path: 'ss/discountset', name: 'SS 할인관리 설정', component: SSDiscountSet, meta: {role: 'BALAANEER'}, },
  // { path: 'ss/errorLog', name: 'ErrorLog', component: SSErrorLog }, // DEV { path: 'dev/glog', name: 'Global Log', component: Glog, meta: {role: 'DEV'}, },
  // dev
  // { path: 'dev/hgsync', name: 'Hub Godo Sync', component: HubGodoSync, meta: {role: 'ADMIN'}, },
  // { path: 'dev/hgsdiff', name: 'Hub Godo Shop Diff', component: HubGodoShopDiff, meta: {role: 'DEV'}, },
  { path: 'dev/queue', name: 'Queue 현황', component: Queue, meta: {role: 'DEV'}},
  { path: 'dev/queue/:host/:id', name: 'Queue 상세', component: QueueDetail, meta: {role: 'DEV', type: 'full'}},
  { path: 'dev/tableWiki', name: 'DB 테이블 및 컬럼설명', component: TableWiki, meta: {role: 'DEV'}, },
  { path: 'dev/ShopStat', name: 'Shop 통계', component: ShopStat, meta: {role: 'DEV'}, },

  // customer
  { path: 'customer/privacy', name: '개인정보 유출내역', component: CustomerPrivacy, meta: {role: 'CUSTOMER_R'}, },

  // godo
  { path: 'customer/list', name: '고객별구매내역', component: CustomerList, meta: {role: 'GODO_R'}, },
  // { path: 'review', name: '리뷰 관리', component: Review, meta: {role: 'REVIEW_R'}, },  // 발란몰 어드민 이전 2023/05/03
  // { path: 'qnaGoods', name: '상품문의', component: QnaGoods, meta: {role: 'REVIEW_R'}, }, // 발란몰 어드민 이전 2023/05/10
  // { path: 'qnaType', name: '1:1 문의 유형 관리', component: QnaType, meta: {role: 'REVIEW_R'}, }, // 발란몰 어드민 이전 2023/05/10
  // { path: 'qna', name: '1:1 문의 관리', component: Qna, meta: {role: 'REVIEW_R'}, }, // 발란몰 어드민 이전 2023/05/10
  // { path: 'main_home', name: '메인 홈 관리', component: MainHome, meta: {role: 'GODO_R'}, }, // 발란몰 어드민 이전 2023/04/06
  // { path: 'main_home/set', name: '메인 홈 설정', component: MainHomeSet, meta: {role: 'GODO_R'}, }, // 발란몰 어드민 이전 2023/04/06
  // { path: 'category_home', name: '카테고리 홈 관리', component: CategoryHome, meta: {role: 'GODO_R'}, }, // 발란몰 어드민 이전 2023/04/14
  // { path: 'category_home/set', name: '카테고리 홈 설정', component: CategoryHomeSet, meta: {role: 'GODO_R'}, }, // 발란몰 어드민 이전 2023/04/14
  { path: 'timesale', name: '타임세일 관리', component: Timesale, meta: {role: 'GODO_R'}, },
  { path: 'timesale/set', name: '타임세일 설정', component: TimesaleSet, meta: {role: 'GODO_R'}, },
  { path: 'popup_detail', name: '팝업 정보', component: PopupDetail, meta: {role: 'GODO_R'}, },
  // { path: 'sale_drop_banner_sets', name: '상시 기획전 관리', component: SaleDropBannerSets, meta: {role: 'GODO_R'}, }, // 발란몰 어드민 이전 2023/07/11
  // { path: 'sale_drop_banner', name: '상시 기획전 상세', component: SaleDropBanner, meta: {role: 'GODO_R'}, }, // 발란몰 어드민 이전 2023/07/11
  // { path: 'event_list', name: '이벤트 목록', component: EventListOld, meta: {role: 'GODO_R'}, }, // 발란몰 어드민 이전 2023/07/11
  // { path: 'event_detail', name: '이벤트 상세', component: EventDetail, meta: {role: 'GODO_R'}, }, // 발란몰 어드민 이전 2023/07/11
  // { path: 'event', name: '기획전', component: EventList, meta: {role: 'GODO_R'}, }, // 발란몰 어드민 이전 2023/07/11
  // { path: 'event/:no', name: '기획전 상세', component: Event, meta: {role: 'GODO_R'}, props: true }, // 발란몰 어드민 이전 2023/07/11
  // { path: 'popup', name: '팝업 관리', component: Popup, meta: {role: 'GODO_R'}, }, // 발란몰 어드민 이전 2023/04/28
  // { path: 'sale_drop_goods_sets', name: '특가 상품 세트 관리', component: SaleDropGoodsSets, meta: {role: 'GODO_R'}, },
  // { path: 'sale_drop_goods_set_detail', name: '특가 상품 세트 설정', component: SaleDropGoods, meta: {role: 'GODO_R'}, }, // 발란몰 어드민 이전 2023/04/28
  // { path: 'banner_page_list', name: '구좌별 배너 관리', component: BannerPageList, meta: {role: 'GODO_R'}, },
  // { path: 'banner_page_detail', name: '구좌별 배너 설정', component: BannerPageDetail, meta: {role: 'GODO_R'}, }, // 발란몰 어드민 이전 2023/04/28
  { path: 'link', name: '링크모음', component: Link, meta: {role: 'BALAANEER'}, },
  { path: 'settings', name: 'Settings', component: Settings, meta: {role: 'BALAANEER'}, },
  { path: 'users', name: '사용자관리', component: Users, meta: {role: 'USER_R'}, },
  { path: 'cs_users', name: '위탁사관리', component: CSUsers, meta: {role: 'USER_CS_R'}, },
  { path: 'audit_logs', name: '감사 로그', component: AuditLogs, meta: {role: 'AUDIT'}, },
  { path: 'mypage', name: '마이페이지', component: Mypage, },
].map(e=>({...e, beforeEnter: auth}));

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: DefaultContainer,
      children: pages
    },
    {
      path: '/pages',
      redirect: '/pages/login',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '403',
          name: 'Page403',
          component: Page403
        },
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        },
        ...pages.map(e=>({...e, name:`[팝업] ${e.name}`}))
      ]
    },
    {path:'*', redirect:'/pages/404'}
  ]
});

router.onError((error, a, b) => {
  if (/Loading .* chunk .* failed/i.test(error.message)) { // 배포 후 조각 hash 가 달라서 메뉴이동이 안될 때
    console.error(error, a, b);
    window.location.reload();
  }
});

export default router;
